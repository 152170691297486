'use strict';
var $ = require('jquery'),


	$body = $('body'),
	$win = $(window),
	orgElementPos,
	orgElementTop;




function Main(){
// =================================================
// = Private variables (example: var _foo = bar; ) =
// =================================================
}




// =================================================
// = public functions                              =
// =================================================
Main.prototype.init = function()
{
	$('.slider').slick({
		arrows: false,
		autoplay: false
	});
	$('.testimonials .arrow').on('click', testimonialClick);
	$('.map').on('click', function()
	{
		$(this).find('iframe').addClass('clicked');
	});
	$('.map').on('mouseleave', function()
	{
		$(this).find('iframe').removeClass('clicked');
	});

	/////////////
	// GENERAL //
	/////////////
	$('.header').on('click', '.header--menu_btn', headerMenuClick);

	if( $body.hasClass('home') )
	{
		var waypoint = new Waypoint({
			element: $('.home--practice')[0],
			offset: 86,
			handler: function(dir)
			{
				if( dir == 'down' )
					$('.header').addClass('sticky');
				else
					$('.header').removeClass('sticky');
			}
		});
	}

	if( $body.hasClass('practice-areas') )
	{
		var curr = $('.practice_area_cont.' + window.location.hash.substr(1));
		curr.addClass('on');

		if( curr.find('ul').length )
		{
			curr.addClass('lower');
		}
		else
		{
			curr.removeClass('lower');
		}

		curr.find('ul li').removeClass('active').eq(0).addClass('active');
		curr.find('.column-group').removeClass('on').eq(0).addClass('on');

		$('.practice_area_cont ul li').on('click', practiceAreaClick);
	}

	if( $body.hasClass('contact') )
	{
		var items = $('form label');
		for( var i = 0; i < items.length; i++ )
		{
			var $curr = $(items[i]);
			var s_html = $curr.html();
			var n_index = s_html.indexOf('<br>');

			if( n_index > 0 )
			{
				var output = s_html.split('<br>');
				output = '<span class="contact--label_left">' + output[0] + '</span>' + output[1];
				$curr.html( output );
			}
		}
	}
}; // end Main.prototype.init




// ================================================
// = Private functions (function _private () {} ) =
// ================================================
/////////////
// GENERAL //
/////////////
function headerMenuClick(evt)
{
	evt.preventDefault();

	$(this).toggleClass('open');
	$('.header--link_cont').toggleClass('open');
}




///////////////
// HOME PAGE //
///////////////
function testimonialClick(e)
{
	e.preventDefault();

	if( $(this).hasClass('prev') )
		$('.slider').slick('slickPrev');
	else
		$('.slider').slick('slickNext');
}



// ########  ########     ###     ######  ######## ####  ######  ########
// ##     ## ##     ##   ## ##   ##    ##    ##     ##  ##    ## ##
// ##     ## ##     ##  ##   ##  ##          ##     ##  ##       ##
// ########  ########  ##     ## ##          ##     ##  ##       ######
// ##        ##   ##   ######### ##          ##     ##  ##       ##
// ##        ##    ##  ##     ## ##    ##    ##     ##  ##    ## ##
// ##        ##     ## ##     ##  ######     ##    ####  ######  ########
function practiceAreaClick(evt)
{
	evt.preventDefault();

	if( !$(this).hasClass('active') )
	{
		$(this).addClass('active').siblings().removeClass('active');
		var newBg = $(this).text().trim().replace(/\s+/g, '').replace(/['"]+/g, '').replace(/[^a-zA-Z ]/g, '').toLowerCase(),
				imageUrl = $('.template_url').text();
		console.log( newBg, imageUrl );
		$(this).parent().prev('.small_hero').css({
			backgroundImage: 'url(' + imageUrl + '/images/' + newBg + '.jpg)'
			// backgroundImage: 'url(/wp-content/themes/legal_theme/images/' + newBg + '.jpg)'
		});
		$(this).closest('.practice_area_cont').find('.column-group').removeClass('on').eq( $(this).index() ).addClass('on');
	}
}





var main = new Main();
window.main = main;
$(document).ready(main.init);
